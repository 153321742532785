<template>
  <section class="form-group">
    <img class="logo mb-6 mt-2" src="../assets/logo_new.svg" />
    <h1 class="is-blue page-title">Login</h1>
    <!--  <h4 v-if="!isGuiderRoute" class="is-light-purple is-size-9 mt-2">
      Are you a guider? <router-link class="is-blue pl-1" to="/guider/login">Login as guider</router-link>
    </h4>
    <h4 v-else class="is-light-purple is-size-9 mt-2">
      Are you a client? <router-link class="is-blue pl-1" to="/login">Login as client</router-link>
    </h4> -->
    <form class="box mt-6">
      <div v-if="validationErrors.length" class="notification is-danger">
        <button @click="resetError()" class="delete"></button>
        <div class="content">
          Please resolve the following error(s) before proceeding.
          <ul style="margin-top:0.3em; margin-left: 1em">
            <li v-for="(error, index) in validationErrors" :key="`error-${index}`" v-html="error" />
          </ul>
        </div>
      </div>

      <b-field label="Email">
        <b-input icon="at" icon-pack="fas" v-model="email" type="email"> </b-input>
      </b-field>

      <b-field label="Password">
        <b-input icon="lock" icon-pack="fas" v-model="password" autocomplete="current-password" type="password"
          password-reveal>
        </b-input>
      </b-field>

      <a class="is-light-purple forgot-password mb-3" @click="resetPassword">Forgot password?</a>

      <b-button :loading="isLoading" @click.prevent="validate()" class="mb-3" type="is-primary" expanded>
        Login
      </b-button>
    </form>
    <p class="is-light-purple is-size-9 is-flex is-justify-content-center">
      Don't have an account? <router-link class="is-blue pl-1" :to="{ name: 'register-guider' }">Register now</router-link>
    </p>

  <!--   <hr class="hr-text mt-6 mb-6" />
    <div class="is-flex is-justify-content-center">
      <b-button icon-pack="fab" icon-left="google" @click="googleSignIn" type="is-primary" outlined>
        Login with Google
      </b-button>
    </div> -->


  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { db } from "../firebase";
import { UserActions, UsersMutations, UsersStates } from "../store/Storetypes";

export default {
  data() {
    return {
      email: null,
      password: null,
      validationErrors: [],
      isGuiderRoute: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.users[UsersStates.USER],
      isEmployee: state => state.users[UsersStates.IS_EMPLOYEE],
      isCompany: state => state.users[UsersStates.IS_COMPANY],
      isAdmin: state => state.users[UsersStates.IS_ADMIN],
      isGuider: state => state.users[UsersStates.IS_USER_A_GUIDER],
      isVendor: state => state.users[UsersStates.IS_VENDOR],
      isLoading:  state => state.users[UsersStates.IS_LOADING],
    }),
    isVendorRoute() {
      return this.$route.name === "vendor-login";
    }
  },
  watch: {
    user() {
      if (!this.user.uid || this.user.isAccountInactive ) return
      if (this.isCompany) {
        this.$router.push({ name: 'company-upcoming-activities' });
        return
      }
      if (this.isEmployee) {
        this.$router.push({ name: 'guider-upcoming-activities' });
        return
      }
      if (this.isAdmin) {
        this.$router.push({ name: "upcoming-activities" });
        return
      }
      if (this.isVendor) {
        this.$router.push({ name: "vendor-dashboard" });
        return
      }
    },
  },
  beforeDestroy(){
    this.setIsLoading(false)
  },
  methods: {
    ...mapActions({
      signIn: UserActions.SIGN_IN,
      googleSignIn: UserActions.SIGN_IN_WITH_GOOGLE
    }),
    ...mapMutations({
      setIsLoading: UsersMutations.SET_IS_LOADING
    }),
    resetError() {
      this.validationErrors = [];
    },
    validate() {
      // Clear the errors before we validate again
      this.resetError();
      // email validation
      if (!this.email) {
        this.validationErrors.push("<strong>E-mail</strong> cannot be empty.");
      }
      if (/.+@.+/.test(this.email) != true) {
        this.validationErrors.push("<strong>E-mail</strong> must be valid.");
      }
      // password validation
      if (!this.password) {
        this.validationErrors.push("<strong>Password</strong> cannot be empty");
      }
      if (/.{6,}/.test(this.password) != true) {
        this.validationErrors.push(
          "<strong>Password</strong> must be at least 6 characters long"
        );
      }
      // when valid then sign in
      if (this.validationErrors.length <= 0) {
        this.signInAction();
      }
    },
    async signInAction() {
      // await this.signIn({ email: this.email, password: this.password, isGuider: this.isGuiderRoute })
      if (this.isVendorRoute) {
        await   await this.signIn({ email: this.email, password: this.password, isVendor: true })
        this.setIsLoading(false)
        return
      }
      await this.signIn({ email: this.email, password: this.password, isGuider: true })
      this.setIsLoading(false)
    },

    resetPassword() {
      this.$buefy.dialog.prompt({
        message: `What's the email you used to sign up?`,
        trapFocus: true,
        inputAttrs: {
          type: "email",
        },
        onConfirm: (value) => {
          db.auth()
            .sendPasswordResetEmail(value)
            .then(() => {
              this.$buefy.toast.open({
                duration: 10000,
                message:
                  "We sent you a link to reset your password. Check your inbox",
                position: "is-bottom",
              });
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 10000,
                message:
                  "There has been an error sending the reset password email. Try again later.",
                position: "is-bottom",
                type: "is-danger",
              });
            });
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(min-content, 1fr));
}

.hr-text {
  overflow: visible;
  /* For IE */
  padding: 0;
  border: none;
  border-top: medium #927BD0;
  color: #A29EBB;
  text-align: center;

  &:after {
    content: "Or";
    font-family: 'Inter', sans-serif;
    display: inline-block;
    position: relative;
    top: -1em;
    font-size: .75em;
    padding: 0 1.75em;
    background: white;
  }
}

hr {
  background-color: #EDEBF2;
}

.button.is-primary.is-outlined {
  border: 2px solid;
  width: 209px;
  border-radius: 6px;
  font-weight: 500;
}

.logo {
  height: 32px;
  width: auto;
}

.notification {
  border: none;
}

.box {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
}

.form-group {
  margin-left: 25%;
  margin-right: 25%;

  .forgot-password {
    float: right;
    font-size: .85rem;
  }
}

.input:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  .box {
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
  }

  #app {
    width: 100%;
  }

  .form-group {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
